<template>
 <div style="margin: 0 auto;text-align: center;height: 80vh">
 </div>
</template>

<script>
export default {
  name: "index",
  data:function (){
    return {
    }
  }
}
</script>

<style scoped>

</style>